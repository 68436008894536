<template>
  <div class='tp-header'>
    <div :class="{'act-name':true,'act-name-center':settingShowElement.hasActNameCenter}" v-if="hasName">
      {{ activity.name }}
    </div>
    <div class='act-count' v-if="hasData">
      <div class='count-item' v-if="hasVoteNum">
        <div class="num">{{ voteNum }}</div>
        <div class="title">
          <span v-if="settingShowElement.totalVoteName">{{ settingShowElement.totalVoteName }}</span>
          <span v-else>总{{ settingShowElement.votesUnit }}数</span>
        </div>
      </div>
      <div class='count-item'>
        <div class="num">{{ playerNum }}</div>
        <div class="title">
          {{ settingShowElement.playerAppellation }}数
        </div>
      </div>
      <div class='count-item'>
        <div class="num">{{ viewNum }}</div>
        <div class="title">
          访问量
        </div>
      </div>
    </div>
    <div class="act-time" v-if="hasTime">
      <template v-if="status.id===2">
        {{ settingShowElement.countdownName ? settingShowElement.countdownName : '活动倒计时' }}：
        <van-count-down :time="status.value" format="DDHHmmss" style="display: inline-block" @finish="finish">
          <template #default="timeData">
            <div class="date">
              <div>{{ timeData.days }}</div>
              天
              <div>{{ timeData.hours }}</div>
              时
              <div>{{ timeData.minutes }}</div>
              分
              <div>{{ timeData.seconds }}</div>
              秒
            </div>
          </template>
        </van-count-down>
      </template>
      <template v-else>{{ status.value }}</template>
    </div>
    <div class='act-rule' v-if="hasRule">
      <div class="rule-title">
        <van-icon name='comment-o'/>活动规则
      </div>
      <div class="rule-content">
          <div class="rule-row">
            <div class="title">
              <van-icon name='clock-o'/>
              {{ settingShowElement.voteStartTitle ? settingShowElement.voteStartTitle : '投票开始' }}：
            </div>
            <div class="content">
              {{ activity.startTime | convertTime }}
            </div>
          </div>
          <div class="rule-row">
            <div class="title">
              <van-icon name='clock-o'/>
              {{ settingShowElement.voteEndTitle ? settingShowElement.voteEndTitle : '投票截止' }}：
            </div>
            <div class="content">
              {{ activity.endTime | convertTime }}
            </div>
          </div>
          <div class="rule-row">
            <div class="title">
              <van-icon name='info-o'/>
              {{ settingShowElement.voteRuleTitle ? settingShowElement.voteRuleTitle : '投票规则' }}：
            </div>
            <div class="content">
              {{
                activity.settingVotingRules.hasRule ? activity.settingVotingRules.customRule : activity.settingVotingRules.baseRule
              }}
            </div>
          </div>
          <div class="rule-row" v-if="activity.settingVotingRules.hasTip">
            <div class="title">
              <van-icon name='info-o'/>
              温馨提示：
            </div>
            <div class="content">
              {{ activity.settingVotingRules.customTip }}
            </div>
          </div>
      </div>
    </div>
    <div class="player-search" v-if="hasSearch">
      <div class='body'>
        <div class="icon"></div>
        <input type="text" v-model='searchKey' :placeholder="settingShowElement.searchTip?settingShowElement.searchTip:('请输入'+settingShowElement.playerAppellation+'编号或名称')">
        <div class="clear" v-show="searchKey">
          <van-icon name="clear" @click='bindClearChange'/>
        </div>
        <button class='btn' @click='bindSearchChange'>搜 索</button>
      </div>
    </div>
    <div :class="{'player-group':true,'unfold':groupUnfold}" v-if="hasPlayerGroup&&groups.length >1">
      <div :class="{'group-list':true,'group-overflow':settingShowElement.hasGroupFold}">
        <div class="group-item" :style="'flex-basis:'+(100/groupColumn)+'%'"
             v-for='(item,index) in groups' :key='index'>
          <div :class="{'item-btn':true ,'active':item.id===groupActive}" @click="bindGroupChange(item.id)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="group-fold" v-if="showGroupFold">
        <div class="fold-btn" @click="groupUnfold=!groupUnfold">
          <van-icon name="arrow-down"/>
        </div>
      </div>
    </div>
    <div class='act-jump' v-if="hasRanking||hasEntry">
      <div :class="{'list':true,'num2':hasRanking&&hasEntry}">
        <div v-if="hasRanking" class="item" v-on:click="bindJumpChange('rank')">
          <div class="text">
            查看排行
          </div>
        </div>
        <div v-if="hasEntry" class="item" v-on:click="bindJumpChange('entry')">
          <div class="text">
            在线报名
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import moment from 'moment'

export default {
  name: 'thHeader',
  components: {},
  data() {
    return {
      state: store.state,
      theme: store.state.themes,
      activity: store.state.activity,
      voteNum: 0,
      viewNum: 0,
      playerNum: 0,
      settingShowElement: store.state.activity.settingShowElement,
      hasName: true,
      hasData: true,
      hasRule: true,
      hasTime: true,
      hasEntry: false,
      hasRanking: false,
      hasVoteNum: true,
      hasSearch: false,
      hasGroup: false,
      hasPlayerGroup: false,
      groupColumn: 1,
      groupActive: 0,
      searchKey: '',
      isInfoShow: true,
      status: '',
      showGroupFold: false,
      groupUnfold: false,
    }
  },
  created() {
    //初始化活动数据
    this.getActivityStatics();
    //初始化显示设置
    this.initShowSetting();
    //初始化状态（进行中的设置倒计时）
    this.initStatus();
    this.initVoteRule();
  },
  props: ['groups'],
  watch: {
    'state.refreshStatisticData': {
      handler(newValue, oldValue) {
        this.getActivityStatics();
      },
    }
  },
  methods: {
    initVoteRule() {
      let rule = this.activity.settingVotingRules;
      if (rule == null) {//数据异常
        this.$router.push('/error');
      }
    },

    initShowSetting() {
      //根据不同页面获取不同的设置
      let setting = '';
      if (store.state.currentRoute == 'blurb') {
        setting = this.activity.settingShowIntroduce;
        this.hasVoteNum = setting.hasTotalVotes;
      } else if (store.state.currentRoute == 'rank') {
        setting = this.activity.settingShowRanking;
        this.hasPlayerGroup = setting.hasPlayerGroup;
        this.hasGroup = setting.showAllGroup;
        // this.groupColumn = setting.playerGroupColumns;
        this.hasVoteNum = true;
      } else {
        setting = this.activity.settingShowVoting;
        this.hasEntry = setting.hasEntry;
        this.hasRanking = setting.hasRanking;
        this.hasVoteNum = setting.hasTotalVotes;
        this.hasSearch = setting.hasPlayerSearch;
        this.hasGroup = setting.showAllGroup;
        this.hasPlayerGroup = setting.hasPlayerGroup;
        // this.groupColumn = setting.playerGroupColumns;
      }
      this.groupColumn = this.activity.settingShowVoting.playerGroupColumns;
      this.hasName = setting.hasActivityName;
      this.hasData = setting.hasActivityData;
      this.hasRule = setting.hasVotingRules;
      this.hasTime = setting.hasCountdown;
      this.isInfoShow = this.hasName || this.hasData || this.hasTime;
      //是否显示折叠
      let hasGroupFold = this.settingShowElement.hasGroupFold;
      if (hasGroupFold) {
        let groups = this.groups;
        let groupColumn = this.groupColumn;
        if (groups && groups.length / groupColumn > 4) {
          this.showGroupFold = true
        }
      }
    },
    getActivityStatics() {
      let url = api.activity.getActivityStatics.replace("{activityId}", this.activity.id);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.voteNum = data.voteNum + data.virtualVoteNum;
        this.viewNum = data.viewNum + data.virtualViewNum;
        this.playerNum = data.playerNum;
      })
    },

    initStatus() {
      let status = this.activity.status;
      let startTime = this.activity.startTime;
      let endTime = this.activity.endTime;
      if (status == null || status == '') {
        this.$router.push('/error');
      } else {
        if (status.index != 1) {
          this.$router.push('/error');
        }
        if (startTime == null || endTime == null) {
          this.$router.push('/error');
        }
        let now = moment().valueOf();
        if (startTime > now) {
          this.status = {id: 1, value: '活动未开始'};
        } else if (startTime < now && endTime > now) {
          this.status = {id: 2, value: endTime - now};
        } else if (endTime < now) {
          this.status = {id: 3, value: '活动已结束'};
        }
      }
    },

    //倒计时结束
    finish() {
      this.status = {id: 3, value: '活动已结束'};
    },
    //跳转
    bindJumpChange(path) {
      this.$router.push(path)
    },
    //清除搜索
    bindClearChange() {
      this.searchKey = ''
    },
    bindSearchChange() {
      let searchKey = this.searchKey;
      this.$emit('onSearch', searchKey)
    },
    bindGroupChange(groupId) {
      this.groupActive = groupId
      this.$emit('onGroup', groupId)
    },
  }
}
</script>
