<template>
  <div class="tp-vote">
    <tp-header :groups="groupList" @onGroup="bindGroupChange" @onSearch="bindSearchChange"></tp-header>
    <div class='vote-player'>
      <div :class="['player-list',playerLayoutClass]">
        <div class="player-column" v-for="(num,index) in playerColumnNum" :key="num">
          <div class="player-item" v-for='item in playerColumnList[index]' :key='item.id'>
            <div class="item-pane" @click="bindPlayerChange(item.id)">
              <div class="item-title">
                <div v-if="config.hasPlayerNumber" class="num-pane">
                  <div class="num">{{ item.num }}</div>
                  <div class="unit">号</div>
                </div>
                <div v-if="config.hasPlayerVotes" class="vote">{{ item.voteNum }} {{ config.voteUnit }}</div>
              </div>
              <div :class="['item-cover',playerCoverClass]" v-if="config.hasPlayerCover">
                <div class="avatar">
                  <div v-if="item.coverType===2" class="video-code" :style="playerCoverStyle"
                       v-html="item.videoCode"></div>
                  <img v-else :src="item.imageUrl|ossAvatarFilter" :style="playerCoverStyle" alt="">
                </div>
                <img class="trim" loading="lazy" v-if="config.trim" :src="config.trim|ossFileFilter" alt="">
              </div>
              <div class="item-info">
                <div class="name-pane">
                  <div v-if="config.hasPlayerName" class="name" :style="playerNameStyle" v-html="item.name"></div>
                </div>
                <div v-if="config.hasPlayerSynopsis"
                     class="descr"
                     :style="playerBlurbStyle" v-html="item.intro"></div>
                <div v-if="config.hasVotingButton" class="btn">
                  <button @click.stop="bindVoteChange(item)">{{
                      item.isSelected ? '已选择' : config.voteBtn
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="scrollEnd" class="scroll-end">
        <span class="loading-tip" v-if="playerFinished">没有更多了</span>
        <span class="loading-tip" v-else>加载中</span>
      </div>
    </div>
    <div class='yd-vote-choose' v-if="selPlayers.length>0">
      <div class="choose-pane">
        <div class='choose-text' v-if="voteRules.votingScopeType.index==1">
          已选<span class="choose-num"> {{ selPlayers.length }} </span>项,
          最少<span class="choose-num"> {{ voteRules.minVotingPlayers }} </span>项,
          最多<span class="choose-num"> {{ voteRules.maxVotingPlayers }} </span>项
        </div>
        <div class='choose-text' v-else>
          全部已选<span class="choose-num"> {{ selPlayers.length }} </span> 项
        </div>
        <button class='choose-btn' v-on:click="bindVoteMultiChange">
          {{ config.voteBtn }}
        </button>
      </div>
    </div>
    <yd-ad-eject :show="isShowEject" :eject="settingAd.ejectAd"
                 :link="settingAd.ejectLink" @close="isShowEject=false"/>
    <yd-vote-captcha :show="showValidator" @onCancel="showValidator=false" @onSubmit="submitVote"/>
    <AuthWxDialog :showDialog="showWxDialog" @onCancel="showWxDialog=false"/>
  </div>
</template>

<script>
import YdVoteCaptcha from '@/components/captcha.vue'
import store from '@/store/store'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {commonUtil} from '@/js/commonUtil'
import {Toast} from 'vant'
import {AuthUtil, StorageUtil} from '@/js/auth'
import {encode} from '@/js/aesencryp'
import AuthWxDialog from "@/components/authWxDialog";
import YdAdEject from "@/components/ad/eject.vue";

export default {
  name: 'tpVote',
  data() {
    return {
      state: store.state,
      actId: 0,//活动ID
      config: {},//基础配置
      voteRules: {},//规则
      pageNo: 1,
      pageCount: 10,
      groupList: [],//组
      settingShowPlayer: {},
      playerColumnIndex: 0,
      playerColumnNum: 2,
      playerColumnList: [[], [], []],//选手
      playerLoading: false,
      playerFinished: false,
      playerList: [],//选手
      playerColumnStyle: {},
      playerLayoutClass: 'def',
      playerCoverClass: 'def',
      playerCoverStyle: {
        'aspect-ratio': 1,
        'object-fit': 'cover',
        'object-position': 'top',
      },
      playerNameStyle: {
        'text-align': 'center',
        '-webkit-line-clamp': 1,
        'height': '0.4rem',
      },
      playerBlurbStyle: {
        'text-align': 'center',
        '-webkit-line-clamp': 1,
        'height': '0.4rem',
      },
      settingAd: {},//广告
      isShowEject: false,
      isVideoPreview: false,
      videoPreview: null,
      //-----------------------
      selGroupId: 0,
      loadFinished: true,
      selPlayers: store.state.selectedPlayers,//多选投票选中的选手列表
      showValidator: false,
      playerIds: [], //要提交的player ids
      showWxDialog: false,
    }
  },
  components: {
    YdAdEject,
    YdVoteCaptcha,
    AuthWxDialog
  },
  watch: {
    'state.searchStatus': function (newVal, oldVal) {
      this.pageNo = 1;
      this.getPlayerList(this.selGroupId, this.state.searchKey);
    }
  },
  created() {
    this.initVote()
    //初始化分组信息
    this.getGroupList();
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll, true);
    })
  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    //初始化基础配置
    initVote() {
      this.pageNo = 1;
      this.playerList = [];
      this.groupList = [];
      store.state.isShowBanner = true;
      this.playerLoading = false
      this.playerFinished = false
      let {
        id,
        settingShowVoting,
        settingStyle,
        settingVotingRules,
        settingShowElement,
        settingAd,
        settingShowPlayer
      } = store.state.activity
      this.actId = id
      this.config = settingShowVoting
      this.voteRules = settingVotingRules
      this.settingAd = settingAd
      //选手封面样式
      this.settingShowPlayer = settingShowPlayer;
      const {
        coverRatio,
        coverFit,
        nameRow,
        nameAlign,
        blurbRow,
        blurbAlign,
        hasBlurbFoldFun,
        hasBlurbFold
      } = settingShowPlayer
      if (coverRatio) {
        const coverRatioInfo = coverRatio.split(':');
        this.playerCoverStyle['aspect-ratio'] = parseInt(coverRatioInfo[0]) / parseInt(coverRatioInfo[1])
      }
      if (nameRow) {
        this.playerNameStyle['-webkit-line-clamp'] = nameRow
        this.playerNameStyle['height'] = nameRow * 0.4 + 'rem'
      }
      if (nameAlign) {
        this.playerNameStyle['text-align'] = nameAlign
      }
      if (blurbRow) {
        this.playerBlurbStyle['-webkit-line-clamp'] = blurbRow
        this.playerBlurbStyle['height'] = blurbRow * 0.4 + 'rem'
      }
      if (blurbAlign) {
        this.playerBlurbStyle['text-align'] = blurbAlign
      }
      if (coverFit) {
        if (coverFit === 1) {
          this.playerCoverStyle['object-fit'] = 'contain';
        } else if (coverFit === 2) {
          this.playerCoverStyle['object-fit'] = 'cover';
          this.playerCoverStyle['object-position'] = 'top';
        } else if (coverFit === 3) {
          this.playerCoverStyle['object-fit'] = 'cover';
          this.playerCoverStyle['object-position'] = 'bottom';
        }
      }
      //选手装饰
      this.config.trim = settingStyle.borderImageUrl
      //投票单位
      this.config.voteUnit = settingShowElement.votesUnit
      //全部按钮名称
      this.config.groupBtnName = settingShowElement.groupBtnName
      //投票按钮
      this.config.voteBtn = settingShowElement.votingButtonName
      //选手布局样式
      let playerColumn = settingShowVoting.playerColumns;
      this.playerColumnNum = playerColumn
      let playerDirection = settingShowVoting.playerShowDirection.index;
      let playerAvatarLayout = settingShowVoting.playerAvatarLayout.index;
      if (playerColumn === 1) {//一列
        if (playerDirection === 1) {//水平
          this.playerLayoutClass = 'hor'
        }
      } else if (playerColumn > 1) {//多列
        if (playerAvatarLayout === 2) {//瀑布
          this.playerCoverClass = 'fall'
          this.playerCoverStyle['aspect-ratio'] = '';
        }
      }
    },
    //获取分组
    getGroupList() {
      this.playerLoading = true;
      let url = api.activity.getGroups.replace('{activityId}', this.actId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        if (this.config.showAllGroup) {
          let groupBtnName = this.config.groupBtnName;
          data.unshift({id: 0, name: groupBtnName ? groupBtnName : '全部'})
        } else if (data.length) {
          this.selGroupId = data[0].id;
        }
        this.groupList = data
        store.setGroups(this.groupList);
        //页面传值
        let key = this.$route.query.searchKey;
        this.getPlayerList(this.selGroupId, key);
      })
    },

    bindGroupChange(groupId) {
      this.selGroupId = groupId;
      this.pageNo = 1;
      this.getPlayerList(groupId);
    },
    handleScroll() {
      let scrollEnd = this.$refs.scrollEnd;
      let scrollEndPosition = scrollEnd.getBoundingClientRect().top;
      if (scrollEndPosition <= window.innerHeight && !this.playerLoading && !this.playerFinished) {
        this.pageNo += 1;
        this.getPlayerList(this.selGroupId);
      }
    },
    //获取选手
    getPlayerList(groupId, key) {
      key = key ? key : '';
      this.playerLoading = true;
      let pageNo = this.pageNo;
      let sortType = this.config.playerSortType.index;
      let url = api.activity.getPlayers.replace('{activityId}', this.actId).replace('{groupId}', groupId)
          .replace('{sortType}', sortType).replace('{showType}', 1).replace('{filterType}', 0)
          .replace('{pageNo}', pageNo).replace('{pageCount}', this.pageCount).replace('{searchKey}', key);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let {totalPage, resultList} = data
        if (pageNo === 1) {
          this.playerColumnIndex = 0
          this.playerColumnList = [[], [], []];
        }
        //待插入列下标
        let playerColumnIndex = this.playerColumnIndex
        let playerColumnNum = this.playerColumnNum;
        let playerColumnList = this.playerColumnList;
        let resultListLength = resultList.length;
        //遍历数组
        for (let i = 0; i < resultListLength; i++) {
          let item = resultList[i];
          item.isSelected = commonUtil.existArrayById(this.selPlayers, item.id);
          let index = (i + playerColumnIndex) % playerColumnNum;
          playerColumnList[index].push(item)
        }
        this.playerColumnIndex = (resultListLength + playerColumnIndex) % playerColumnNum
        this.playerLoading = false;
        this.playerFinished = pageNo === totalPage || !resultList.length;
      }, err => {
        this.loadFinished = true;
        this.playerFinished = false;
      })
    },

    //跳转到详情页面
    bindPlayerChange(playerId) {
      if (this.config.hasPlayerDetails) {
        let groupId = this.selGroupId;
        StorageUtil.storeObject('playerPage', {id: playerId, groupId: groupId})
        this.$router.push({path: 'playerDetail', query: {activityId: this.actId, p: playerId + '_' + groupId, t: new Date().getTime()}})
      }
    },
    //点击投票按钮
    bindVoteChange(item) {
      //微信授权登录
      let that = this;
      AuthUtil.wechatAuthLogin(true, isAuthOk => {
        if (isAuthOk) {
          that.voteRuleVerify(item);
        } else {
          that.showWxDialog = true;
        }
      })
    },
    //投票规则验证
    voteRuleVerify(player) {
      //进入高能区，注意
      if (player.isSelected) {//若当前是已选，则取消选择并完成
        let index = commonUtil.findIndexFromArrayById(this.selPlayers, player.id);
        if (index > -1) {//从选中数组中删除
          this.selPlayers.splice(index, 1);
        }
        player.isSelected = false;
        return;
      }
      let {votingScopeType, maxVotingPlayers, chooseType} = this.voteRules;
      //当状态由未选中改为选中时
      let voteScope = votingScopeType.index;
      let submitType = 0;
      let maxVoteNum = 0;
      if (voteScope === 1) { //整个活动（不区分各个分组规则）
        maxVoteNum = maxVotingPlayers;
        submitType = chooseType ? chooseType.index : null;
        if (submitType === 1) {//单选时，直接提交
          this.playerIds = [player.id]
          this.votePlayers();
        } else { //多选时，按钮只负责选中,不提交
          //检查是否超出规则限制的最大投票数
          if (this.selPlayers.length < maxVoteNum) {
            this.selPlayers.push({id: player.id, groupId: player.groupId});
            player.isSelected = true;
          } else {
            //错误提示
            Toast('最多只能选择' + maxVoteNum + '个');
          }
        }
      } else if (voteScope == 2) {//单分组情况下
        //检查已选列表中是否有已选选手，并判断当前选手分组是否和已选选手分组一致，如果不一致，则提示错误
        let currrentSelectedPlayerGroupId = this.selPlayers.length > 0 ? this.selPlayers[0].groupId : 0;
        if (currrentSelectedPlayerGroupId > 0 && currrentSelectedPlayerGroupId != player.groupId) {
          let previousSelectedPlayerGroup = this.groupList[commonUtil.findIndexFromArrayById(this.groupList, this.selPlayers[0].groupId)]
          Toast('已选' + previousSelectedPlayerGroup.name + '选手，请先给' + previousSelectedPlayerGroup.name + '投票');
          return;
        }

        //获取选手所在分组的规则
        let group = this.groupList[commonUtil.findIndexFromArrayById(this.groupList, player.groupId)]
        submitType = group.chooseType ? group.chooseType.index : null;
        maxVoteNum = group.maxVotingPlayers;

        if (submitType === 1) {//单选时，直接提交
          this.playerIds = [player.id]
          this.votePlayers();
        } else { //多选时，按钮只负责选中或取消
          //检查是否超出规则限制的最大投票数
          if (this.selPlayers.length < maxVoteNum) {
            this.selPlayers.push({id: player.id, groupId: player.groupId});
            player.isSelected = true;
          } else {
            //错误提示
            Toast('最多只能选择' + maxVoteNum + '个');
          }
        }
      } else if (voteScope === 3) { //多分组的规则下，可以跨分组选择，但必须按各个分组的规则来
        //获取选手所在分组的规则
        let group = this.groupList[commonUtil.findIndexFromArrayById(this.groupList, player.groupId)]
        submitType = group.chooseType ? group.chooseType.index : null;
        maxVoteNum = group.maxVotingPlayers;
        //检查是否超出当前组规则限制的最大投票数
        let voteNumForCurrentGroup = 0;
        for (let i in this.selPlayers) {//查询当前分组下已投票数量
          if (this.selPlayers[i].groupId === player.groupId) {
            voteNumForCurrentGroup = voteNumForCurrentGroup + 1;
          }
        }
        if (voteNumForCurrentGroup < maxVoteNum) {
          this.selPlayers.push({id: player.id, groupId: player.groupId});
          player.isSelected = true;
        } else {
          //错误提示
          Toast('最多只能选择' + maxVoteNum + '个');
        }
      }
    },
    //查询
    bindSearchChange(searchKey) {
      this.pageNo = 1;
      this.getPlayerList(this.selGroupId, searchKey);
    },

    bindVoteMultiChange() { //多选提交vote
      let selPlayers = this.selPlayers;
      if (selPlayers.length && this.voteMultiRuleVerify()) {
        let playerIds = []
        for (let i in selPlayers) {
          playerIds.push(selPlayers[i].id);
        }
        this.playerIds = playerIds
        this.votePlayers();
      }
    },

    voteMultiRuleVerify() {
      let voteScope = this.voteRules.votingScopeType.index;
      let minVoteNum = 0;
      if (voteScope == 1) {//整个活动多选时
        minVoteNum = this.voteRules.minVotingPlayers
        if (this.selPlayers.length < minVoteNum) {
          Toast('至少选择' + minVoteNum + '项');
          return false;
        }
      } else if (voteScope == 2) {//单个分组多选时

        let groupId = this.selPlayers[0].groupId;
        let group = this.groupList[commonUtil.findIndexFromArrayById(this.groupList, groupId)]
        minVoteNum = group.minVotingPlayers;
        if (this.selPlayers.length < minVoteNum) {
          Toast(group.name + '至少选择' + minVoteNum + '项');
          return false;
        }
      } else if (voteScope == 3) {//多分组时,检查每个分组的最小数量
        //先计算各分组下已选数量
        let selectedNumForEachGroupId = [];
        for (var i in this.selPlayers) {
          let index = commonUtil.findIndexFromArrayById(selectedNumForEachGroupId, this.selPlayers[i].groupId);
          if (index > -1) {
            selectedNumForEachGroupId[index].num = selectedNumForEachGroupId[index].num + 1;
          } else {
            selectedNumForEachGroupId.push({id: this.selPlayers[i].groupId, num: 1});
          }
        }

        for (var j in selectedNumForEachGroupId) {
          let group = this.groupList[commonUtil.findIndexFromArrayById(this.groupList, selectedNumForEachGroupId[j].id)];
          if (selectedNumForEachGroupId[j].num < group.minVotingPlayers) {
            Toast(group.name + '至少选择' + group.minVotingPlayers + '项');
            return false;
          }
        }
      }
      return true;
    },

    votePlayers() {
      if (this.voteRules.verificationCodeProtective) {
        this.showValidator = true;
      } else {
        this.submitVote();
      }
    },

    submitVote() {
      this.showValidator = false;
      let url = api.player.vote;
      let user = store.state.user;
      let regUuid = AuthUtil.getSpecifyUuid("vote");
      let validateToken = encode(regUuid);
      let req = {
        activityId: this.actId, playerIds: this.playerIds, wxNickName: user.wxNickName,
        wxOpenId: user.wxOpenId, wxAvatar: user.wxAvatar, validateToken: validateToken
      };
      let that = this;
      ajaxUtil.ajaxJson(url, req, 'POST').then(data => {
        if (that.selPlayers.length > 0) { //多选情况下，刷新多选数据及页面
          that.selPlayers.splice(0, that.selPlayers.length); //清空多选列表
        }
        //刷新活动统计数据
        store.state.refreshStatisticData = store.state.refreshStatisticData ? false : true;
        let hasEject = that.settingAd.hasEject;
        if (hasEject) {
          that.isShowEject = true
        } else {
          that.$toast.success('投票成功！')
        }
        that.pageNo = 1;
        that.getPlayerList(that.selGroupId); //重新加载渲染数据
      }, err => {
        let errResponse = err.response;
        let errData = errResponse == null ? null : errResponse.data;
        let customError = errData == null ? null : errData.data;
        if (customError == null) {
          Toast('投票失败！')
        } else {
          let errorCode = customError.errorCode;
          let definedCodes = [3010, 3001, 3004, 3005, 3006, 3007, 3015, 3016, 3017, 3013, 1206, 1207, 1208, 3012, 3011, 3014, 1055];
          if (definedCodes.indexOf(errorCode) > -1) {
            Toast(customError.message);
          } else {
            Toast('投票失败！')
          }
        }

      })
    },
  }
}
</script>
<style scoped lang='less'>
.tp-vote {
  .vote-player {
    margin-top: 0.3rem;
    padding: 0 0.18rem;
    box-sizing: border-box;

    .player-column {
      margin-right: 0.18rem;
      box-sizing: border-box;
      width: 100%;
    }

    .player-column:last-child {
      margin-right: 0;
    }

    .player-list {
      box-sizing: border-box;
      display: flex;

      .player-item {
        box-sizing: border-box;

        .item-pane {
          position: relative;
          box-sizing: border-box;

          .item-cover.fall {
            .avatar {
              height: auto;
            }
          }
        }
      }
    }

    .scroll-end {
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      color: #999999;
    }
  }
}

</style>
